import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
  })
);

export const HeadingWithDetails: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};
