import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { ListingItemProps } from '@models/Listing';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container, makeStyles, Typography, Link } from '@material-ui/core';
import { NextButton } from '@components/next-button/next-button';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(5),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: '100%',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: theme.spacing(20),
    margin: '0',
  },
  postTitle: {
    margin: theme.spacing(1),
    '& a': {
      color: 'inherit',
    },
  },
}));

export const ListingItem: React.FC<ListingItemProps> = ({
  author,
  thumbnail,
  directUrl,
  title,
  excerpt,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <div className={classes.imageContainer}>
        <Link to={directUrl} component={GatsbyLink}>
          {thumbnail && (
            <GatsbyImage
              alt={title}
              image={thumbnail}
              className={classes.image}
            />
          )}
        </Link>
      </div>
      <div>
        <Typography variant="h4" className={classes.postTitle}>
          <Link to={directUrl} component={GatsbyLink}>
            {title}
          </Link>
        </Typography>
      </div>
      <div>
        <Typography variant="body2">autor {author}</Typography>
        <Typography variant="body1">{excerpt}</Typography>
        <NextButton to={directUrl} color="secondary" variant="outlined">
          czytaj dalej
        </NextButton>
      </div>
    </Container>
  );
};
