import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import classNames from 'classnames';

interface ImageProps {
  alt: string;
  image: IGatsbyImageData;
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(4),
      height: '100%',
    },
    containerLarge: {
      padding: theme.spacing(8),
    },
    image: {
      borderRadius: '100%',
      maxWidth: '100%',
      height: 'auto',
    },
  })
);

export const RoundedImage: React.FC<ImageProps> = ({ image, alt }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const classnames = classNames(classes.container, {
    [classes.containerLarge]: matches,
  });

  return (
    <div className={classnames}>
      <GatsbyImage alt={alt} image={image} className={classes.image} />
    </div>
  );
};
