import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface AllExperimentsQueryResult {
  markdown: {
    totalCount: number;
    edges: {
      node: {
        id: string;
        slug: string;
        frontmatter: {
          author: string;
          excerpt: string;
          title: string;
          image: string;
          keywords: string;
          thumbnailUrl: string;
          publishedAt: string;
        };
      };
    }[];
  };
  images: {
    edges: {
      node: {
        gatsbyImageData: IGatsbyImageData;
        resize: {
          originalName: string;
        };
      };
    }[];
  };
}

export const useAllExperiments = (): AllExperimentsQueryResult =>
  useStaticQuery<AllExperimentsQueryResult>(graphql`
    query TypeMarkdownQuery {
      markdown: allMdx(
        sort: { fields: frontmatter___publishedAt, order: DESC }
        filter: { fields: { slug: { regex: "/experiments/" } } }
        limit: 3
      ) {
        totalCount
        edges {
          node {
            id
            slug
            frontmatter {
              author
              excerpt
              title
              image
              keywords
              publishedAt
            }
          }
        }
      }
      images: allImageSharp {
        edges {
          node {
            gatsbyImageData(
              height: 150
              width: 150
              transformOptions: { cropFocus: CENTER }
            )
            resize {
              originalName
            }
          }
        }
      }
    }
  `);
