import React from 'react';
import { VerticalListing } from '@components/vertical-listing/vertical-listing';
import { NextButton } from '@components/next-button/next-button';
import { transformToListingProps } from '../../utils/transform-to-listing-props';
import { useAllExperiments } from '../../queries/use-all-experiments';

export const ExperimentsListing: React.FC = () => {
  const data = useAllExperiments();

  return (
    <>
      <VerticalListing items={transformToListingProps(data)} />
      <NextButton to="/experiments" color="inherit" variant="outlined">
        Zobacz wszystkie
      </NextButton>
    </>
  );
};
