import React from 'react';

import { graphql, PageProps } from 'gatsby';
import { FullScreenLayout } from '@components/layouts';
import { Section } from '@components/section/section';
import { HeadingWithDetails } from '@components/heading-with-details/heading-with-details';
import { RoundedImage } from '@components/rounded-image/rounded-image';
import { ExperimentsListing } from '@components/experiments-listing/experiments-listing';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useSetHeaderContext } from '../hooks/useSetHeaderContext';

interface WithImage {
  data: {
    banner: {
      gatsbyImageData: IGatsbyImageData;
    };
    userAvatar: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: 'center',
  },
  textJustify: {
    textAlign: 'justify',
  },
  textDynamicCenter: {
    '@media(max-width: 1279px)': {
      textAlign: 'center',
    },
  },
}));

const Home: React.FC<PageProps & WithImage> = ({ data }) => {
  useSetHeaderContext({
    image: data.banner.gatsbyImageData,
    title: 'dzień dobry',
    subtitle: 'każdy dzień to okazja, aby nauczyś się czegoś ciekawego',
    height: 500,
  });

  const classes = useStyles();

  return (
    <FullScreenLayout>
      <Section alignment="center">
        <Typography variant="h5" component="p" className={classes.textCenter}>
          MIŁO CIĘ TUTAJ WIDZIEĆ
        </Typography>
        <Typography variant="h4" component="p" className={classes.textCenter}>
          <strong>Nazywam się Jakub Bartusiak</strong>
        </Typography>
        <Typography className={classes.textCenter}>
          Jestem absolwentem Politechniki Wrocławskiej i programistą o szerokich
          zainteresowaniach.
        </Typography>
        <Typography className={classes.textCenter}>
          Staram się stale rozwijać swoje umiejętności i codziennie uczyć się
          czegoś nowego.
        </Typography>
      </Section>
      <Section variant="dark" alignment="center">
        <Typography variant="h4" component="p">
          Dwa słowa o mnie
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={12} lg={4}>
            <Typography variant="body1" className={classes.textJustify}>
              Jestem osobą ambitną i lubię wiedzieć jak najwięcej. Nie lubię też
              prosić się innych o pomoc, dlatego dążę do posiadania dużej ilości
              umiejętności i technicznego know-how, tak aby być jak najbardziej
              niezależny.
            </Typography>
            <br />
            <Typography variant="body1" className={classes.textJustify}>
              Nie zmienia to faktu, że dobrze pracuję w grupie, a koledzy i
              koleżanki zawsze chwalą sobie moje towarzystwo, zarówno ze
              względów praktycznych (dobra jakość pracy), jak też towarzyskich
              (niecodziennie - ale dobre poczucie humoru).
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} style={{ textAlign: 'center' }}>
            <RoundedImage
              image={data.userAvatar.gatsbyImageData}
              alt="users avatar"
            />
          </Grid>
          <Grid item xs={12} lg={4} className={classes.textDynamicCenter}>
            <HeadingWithDetails>
              <Typography variant="h5">JAVA / SPRING</Typography>
              <Typography variant="body2">
                Java 7+, Spring, Hibernate, Thymeleaf, REST, Mockito, JUnit,
                Thymeleaf
              </Typography>
            </HeadingWithDetails>
            <HeadingWithDetails>
              <Typography variant="h5">HTML / CSS</Typography>
              <Typography variant="body2">
                HTML 5, CSS 2+, SCSS, Bootstrap
              </Typography>
            </HeadingWithDetails>
            <HeadingWithDetails>
              <Typography variant="h5">JAVASCIPT</Typography>
              <Typography variant="body2">
                ES 2015+, Webpack, Babel, Npm, Angular 2+, React, Gatsbyjs, Type
                Script
              </Typography>
            </HeadingWithDetails>
            <HeadingWithDetails>
              <Typography variant="h5">INNE</Typography>
              <Typography variant="body2">
                Kubernetes, MySQL, Oracle DB, Amazon Web Services, Jenkins,
                Maven, Gradle, Android SDK... wymieniać dalej? :)
              </Typography>
            </HeadingWithDetails>
          </Grid>
        </Grid>
      </Section>
      <Section variant="green" alignment="center">
        <Typography variant="h4">Najnowsze wpisy na blogu</Typography>
      </Section>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Section variant="dark" alignment="center">
            <Typography variant="h4">Eksperymenty</Typography>
            <ExperimentsListing />
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section alignment="center">
            <Typography variant="h4">Inne wiadomości</Typography>
          </Section>
        </Grid>
      </Grid>
    </FullScreenLayout>
  );
};

export default Home;

export const query = graphql`
  query LandingPageQuery {
    banner: imageSharp(
      resize: { originalName: { regex: "/^homepage-backdrop/" } }
    ) {
      gatsbyImageData(transformOptions: { cropFocus: CENTER, fit: COVER })
      id
    }
    userAvatar: imageSharp(
      resize: { originalName: { regex: "/^user-avatar/" } }
    ) {
      gatsbyImageData(transformOptions: { cropFocus: CENTER, fit: COVER })
      id
    }
  }
`;
