import React from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as GatsbyLink } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export const NextButton: React.FC<ButtonProps & { to: string }> = ({
  children,
  to,
  variant,
  color,
}) => {
  const classes = useStyles();

  return (
    <Button
      endIcon={<NavigateNextIcon />}
      component={GatsbyLink}
      variant={variant}
      color={color}
      to={to}
      className={classes.root}
    >
      {children}
    </Button>
  );
};
