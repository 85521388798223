import { ListingItemProps } from '@models/Listing';
import { fullDateFormat } from './date-utils';
import { AllExperimentsQueryResult } from '../queries/use-all-experiments';

export const transformToListingProps = ({
  markdown,
  images,
}: AllExperimentsQueryResult): ListingItemProps[] =>
  markdown.edges.map(({ node }) => ({
    author: node.frontmatter.author,
    title: node.frontmatter.title,
    excerpt: node.frontmatter.excerpt,
    publishedAt: fullDateFormat(node.frontmatter.publishedAt),
    directUrl: node.slug,
    thumbnail: images.edges.find(
      image => image.node.resize.originalName === node.frontmatter.image
    )?.node.gatsbyImageData,
  }));
