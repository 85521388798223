import React from 'react';
import { ListingItemProps } from '@models/Listing';
import { Container, makeStyles } from '@material-ui/core';
import { ListingItem } from './listing-item';

export interface VerticalListingProps {
  items: ListingItemProps[];
}

const useStyles = makeStyles(() => ({
  root: {
    margin: '40px 0',
    flexDirection: 'column',
  },
}));

export const VerticalListing: React.FC<VerticalListingProps> = ({ items }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      {items.map(item => (
        <ListingItem {...item} key={item.directUrl} />
      ))}
    </Container>
  );
};
